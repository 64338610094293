import { createApp } from "vue";
import { createStore } from "vuex";
import App from "./App.vue";
import router from "./router";
import { getCookie, setCookie } from "@/utils/cookies";

const store = createStore({
  state() {
    return {
      publicApiUrl: "",
      eventId: getCookie("eventId") || null,
      myPosition: 0,
      queuePosition: 0,
      token: null,
      customData: getCookie("customData") || null,
      launchQueryParameters: "",
      requestId: getCookie("requestId") || null,
      callbackUri: getCookie("callbackUri") || null,
    };
  },
  mutations: {
    setRequestId(state, requestId) {
      state.requestId = requestId;
      setCookie("requestId", requestId, 3600);
    },
    setMyPosition(state, position) {
      state.myPosition = position;
    },
    setQueuePosition(state, position) {
      state.queuePosition = position;
    },
    setToken(state, token) {
      state.token = token;
    },
    setPublicApiUrl(state, url) {
      state.publicApiUrl = url;
    },
    setEventId(state, eventId) {
      state.eventId = eventId;
      setCookie("eventId", eventId, 3600);
    },
    setCustomData(state, customData) {
      state.customData = customData;
      setCookie("customData", customData, 3600);
    },
    setCallbackUri(state, callbackUri) {
      state.callbackUri = callbackUri;
      setCookie("callbackUri", callbackUri, 3600);
    },
    setCommerceApiUrl(state, url) {
      state.commerceApiUrl = url;
    },
    setLaunchQueryParameters(state, query) {
      state.launchQueryParameters = query;
    },
  },
  getters: {
    hasRequestId(state) {
      return state.requestId !== null;
    },
    hasQueuePosition(state) {
      return state.myPosition > 0;
    },
    hasToken(state) {
      return state.token !== null;
    },
    hasReceipt(state) {
      return state.receipt !== null;
    },
  },
});

const app = createApp(App);
app.use(router);
app.use(store);
app.mount("#app");
