import axiosClient from "@/utils/axios";

export async function retrieveTokenAndRedirect(store) {
    const resource = `${store.state.publicApiUrl}/generate_token`;
    const { eventId, requestId, customData, callbackUri } = store.state
    const body = {
        event_id: eventId,
        request_id: requestId,
        ...(customData ? { custom_data: customData } : {}),
        ...(callbackUri ? { callback_uri: callbackUri } : {}),
    };

    try {
        const response = await axiosClient().post(resource, body);
        store.commit("setToken", response.data.access_token);
        window.location.href = response.data.destination_url;
    } catch (error) {
        console.log(error);
        store.state.tokenRetrievalFailed = true; // Ensure this state exists in your store
    }
}