<template>
  <div class="vl-parent">
    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :width="50"
    >
      <div
        tabindex="0"
        class="vl-overlay vl-active vl-full-page"
        aria-busy="true"
        aria-label="Loading"
      >
        <div class="vl-background"></div>
        <div
          class="vl-icon"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          "
        >
          <LoadingSpinner />
          <div class="mt-2 text-center">
            <div>Queueing...</div>
            <MyPosition />
            <ServingPosition />
            <WaitingRoomSize />
          </div>
        </div>
      </div>
    </loading>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import { mapGetters } from "vuex";
import MyPosition from "@/components/MyPosition.vue";
import ServingPosition from "@/components/ServingPosition.vue";
import WaitingRoomSize from "@/components/WaitingRoomSize.vue";
import { getCookie } from "@/utils/cookies";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { retrieveTokenAndRedirect } from "@/utils/retrieveTokenAndRedirect";

export default {
  name: "WaitingRoom",
  data() {
    return {
      isLoading: true,
      tokenRetrievalFailed: false,
    };
  },
  created() {
    this.$watch("readyForEntry", this.passThruEntrance);
    if (!this.$store.state.requestId) {
      const storedRequestId = getCookie("requestId");
      if (storedRequestId) {
        this.$store.commit("setRequestId", storedRequestId);
      }
    }
    if (!this.$store.state.eventId) {
      const storedEventId = getCookie("eventId");
      if (storedEventId) {
        this.$store.commit("setRequestId", storedEventId);
      }
    }
  },
  methods: {
    async passThruEntrance() {
      console.log("readyForEntry:", this.readyForEntry);
      if (this.readyForEntry) {
        await retrieveTokenAndRedirect(this.$store);
      }
    },
  },
  computed: {
    ...mapGetters(["hasRequestId", "hasQueuePosition", "hasToken"]),
    myPosition() {
      return this.$store.state.myPosition;
    },
    queuePosition() {
      return this.$store.state.queuePosition;
    },
    readyForEntry() {
      return (
        this.hasRequestId &&
        this.myPosition &&
        this.queuePosition &&
        this.myPosition <= this.queuePosition
      );
    },
  },
  components: {
    MyPosition,
    ServingPosition,
    WaitingRoomSize,
    Loading,
    LoadingSpinner,
  },
};
</script>
