<template>
  <div class="vl-parent">
    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      after
    />
    <div v-if="entryFailed" class="alert alert-danger" role="alert">
      An error occurred while entering the line
      <span v-if="errorMsg">:{{ errorMsg }}</span>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import { setCookie } from "@/utils/cookies";
import { retrieveTokenAndRedirect } from "@/utils/retrieveTokenAndRedirect";

import axiosClient from "@/utils/axios";

export default {
  data() {
    return {
      isLoading: true,
      entryFailed: false,
      errorMsg: "",
    };
  },
  components: {
    Loading,
  },
  async mounted() {
    await this.requestQueuePosition();
    await this.retrieveLinePosition();
  },
  methods: {
    async requestQueuePosition() {
      const eventId = this.$store.state.eventId;
      if (!eventId) {
        this.$router.push({ name: "404" });
      }
      const resource = `${this.$store.state.publicApiUrl}/assign_queue_num`;
      const body = {
        event_id: eventId,
      };
      const store = this.$store;
      const component = this;
      try {
        const response = await axiosClient().post(resource, body);
        if (response.data.api_request_id) {
          store.commit("setRequestId", response.data.api_request_id);
          setCookie("requestId", response.data.api_request_id, 3600);
        } else {
          throw "Request Queue Number Error";
        }
      } catch (error) {
        console.log({ error });
        component.entryFailed = true;
      }
    },
    async retrieveLinePosition() {
      const eventId = this.$store.state.eventId;
      const requestId = this.$store.state.requestId;
      if (!eventId || !requestId) return;
      const resource = `${this.$store.state.publicApiUrl}/queue_num?event_id=${eventId}&request_id=${requestId}`;
      const store = this.$store;
      const component = this;
      try {
        const response = await axiosClient().get(resource);
        store.commit(
          "setMyPosition",
          Number.parseInt(response.data.queue_number)
        );
        if (response.status === 200) {
          if (response.data.within_capacity) {
            await retrieveTokenAndRedirect(this.$store);
          } else {
            this.$router.push({
              name: "Waiting Room",
            });
          }
        }
      } catch (error) {
        console.log({ error });
        component.entryFailed = true;
      }
    },
  },
};
</script>
